import React from "react";
import PhoneInTalkRoundedIcon from "@material-ui/icons/PhoneInTalkRounded";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import "./ContactUs.css";
import Header2 from "./Header2";
import Footer from "./Footer";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import contact from "../Media/Contact us.svg"
function ContactUs() {
  return (
    <>
      <div className="contactus2__header" data-aos="slide-left">
        <Header2 />
        <div className="contactus2">
        <img className="contactus2__image" src={contact} alt="" />
          <div className="contactus2__text">
            <p className="contactus2__text__heading">
              At Arush Finserv, we are always there to help you, contacts us any
              time any day.
            </p>
          </div>
          
        </div>
      </div>
      <div className="contactus2__icons" data-aos='fade-up'>
            <div className="contactus2__icons__main">
              <PhoneInTalkRoundedIcon className="c__icon1" />
              <span className="contactus2__icons__texts">
              T - 011-4752-1888
              </span>
            </div>
            <div className="contactus2__icons__main" data-aos='fade-up'>
              <LocationOnIcon className="c__icon1" />
              <span className="contactus2__icons__texts">
              Ground Floor, 2/81-82, LALITA PARK, LAXMI NAGAR, <br />
              East Delhi, Delhi, 110092
              </span>
            </div>
            <div className="contactus2__icons__main" data-aos='fade-up'>
              <MailOutlineRoundedIcon className="c__icon1" />
              <span className="contactus2__icons__texts">
              info@arushfinserv.in
              </span>
            </div>
          </div>
      <Footer />
    </>
  );
}

export default ContactUs;
