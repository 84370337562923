import React from "react";
import bank1 from "../Media/banks/Aditya Birla.svg";
import bank2 from "../Media/banks/Axis Bank.svg";
import bank3 from "../Media/banks/Bajaj.svg";
import bank4 from "../Media/banks/bandhanbank.svg";
import bank5 from "../Media/banks/DCB_1.svg";
import bank6 from "../Media/banks/Edelweiss.svg";
import bank7 from "../Media/banks/Fullerton.svg";
import bank8 from "../Media/banks/HDB.svg";
import bank9 from "../Media/banks/Hero .svg";
import bank10 from "../Media/banks/HDFC.svg";
import bank11 from "../Media/banks/ICICI .svg";
import bank12 from "../Media/banks/IDFC Bank.svg";
import bank13 from "../Media/banks/Indus.svg";
import bank14 from "../Media/banks/kotak.svg";
import bank15 from "../Media/banks/lendingkart3.svg";
import bank16 from "../Media/banks/RBL Bank.svg";
import bank17 from "../Media/banks/SC bank.svg";
import bank18 from "../Media/banks/Shinhan.svg";
import bank19 from "../Media/banks/tata.svg";
import bank20 from "../Media/banks/Yes Bank.svg";
import "./Bank.css";
function Bank() {
  return (
    <div className="bank">
      <div className="bank__text">
        <h2 className="bank__title">Proud to be Associated with India's leading Banks & NBFC's</h2>
        <ul className="bank__list">
          <li>Zero Commission</li>
          <li>Wide range to compare</li>
        </ul>
      </div>
      <div className="bank__images">
        <div className="bank__image">
          <img className="bank__image__bank" src={bank1} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank2} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank3} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank4} alt="" data-aos="fade-left" />
        </div>
        <div className="bank__image">
          <img className="bank__image__bank" src={bank5} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank6} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank7} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank8} alt="" data-aos="fade-left" />
        </div>
        <div className="bank__image">
          <img className="bank__image__bank" src={bank9} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank10} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank11} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank12} alt="" data-aos="fade-left" />
        </div>
        <div className="bank__image">
          <img className="bank__image__bank" src={bank13} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank14} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank15} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank16} alt="" data-aos="fade-left" />
        </div>
        <div className="bank__image">
          <img className="bank__image__bank" src={bank17} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank18} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank19} alt="" data-aos="fade-left" />
          <img className="bank__image__bank" src={bank20} alt="" data-aos="fade-left" />
        </div>
      </div>
    </div>
  );
}

export default Bank;
