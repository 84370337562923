import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header2.css";
import logo from "../Media/Logo.svg";
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
function Header2() {
  function respo() {
    if(hamburger){
      setHamburger(false);
      
    }else {
      if(dropdown){
        setDropdown(false);
      }
      setHamburger(true);
    }
  }
  function dropDown(){
    if(dropdown){
      setDropdown(false);
    }else {
      setDropdown(true);
    }
  }

  function expand(){
    if (expandM){
      setOpen(false);
    }else{
      setOpen(true);
    }
  }

  const [hamburger, setHamburger] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [expandM, setOpen] = useState(false);
  return (
    <nav className="header">
      <Link to="/" className="header__link">
        <img className="header__logo" src={logo} alt="" />
        <span className="header__title"> ARUSH FINSERV </span>
      </Link>

      <div className={hamburger ?"header__nav ":"header__nav headmenu" }>
        <Link to="/" className="header__link">
          <div className="header__option" >
            <span>
              <strong>HOME</strong>
            </span>
          </div>
        </Link>
        <div className="header__option hand" >
          <div className="dropdown" onClick={dropDown}>
          <div className="header__link dropbtn"  >
            <span>
              <strong>LOAN </strong>
            </span>
            <ExpandMoreIcon className={expandM?"expandM dNone":"expandM "} onClick={expand} />
            <ExpandLessIcon className={expandM?"expandL":"expandL dNone"} onClick={expand} />
          </div>
            <div className={dropdown?"dropdown-content ":"dropdown-content dNone"}>
            <Link to="/PersonalLoan" className="header__link">
            <span>
              <strong>Personal Loan</strong>
            </span>
            </Link>
            <Link to="/Businessloan" className="header__link">
            <span>
              <strong>Business Loan</strong>
            </span>
            </Link>
            <Link to="/ProfessionalLoan" className="header__link">
            <span>
              <strong>Professional Loan</strong>
            </span>
            </Link>
            <Link to="/LoanAgainstProperty" className="header__link">
            <span>
              <strong>Loan Against Property</strong>
            </span>
            </Link>
            
            </div>
          </div>
        </div>
        <Link to="/contactus" className="header__link">
          <div className="header__option" >
            <span>
              <strong>CONTACT US</strong>
            </span>
          </div>
        </Link>
        <Link to="/Partner" className="header__link">
          <div className="header__option" >
            <span>
              <strong>BECOME A PARTNER</strong>
            </span>
          </div>
        </Link>
        <div className="header__option" >
        <Link to="/BasicDetail" className="header__link">
          <button className="header__button">
            <strong>Apply Now</strong>
          </button>
        </Link>
        </div>
      </div>
      <div className="header__option menu" onClick={respo}>
          <MenuIcon />
      </div>
    </nav>
  );
}

export default Header2;
