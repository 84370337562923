import React, {useEffect} from "react";
import Header2 from "./Header2";
import Footer from "./Footer";
import lap from "../Media/Loan Against Property.svg";
import "./LoanAgainstProperty.css";
import { Link } from "react-router-dom";
function LoanAgainstProperty() {
  useEffect(()=>{
    return window.scrollTo(0,0)
  })
  return (
    <div>
      <div>
        <Header2 />
        <div className="loanAgainstProperty">
          <div className="loanAgainstProperty__image"
          data-aos="slide-left">
            <div className="loanAgainstProperty__image__heading">
              <h1 className="loanAgainstProperty__image__heading__title">
                Get liquidity against your property at a great interest rates and flexible tenure
              </h1>
            </div>
            <img
              className="loanAgainstProperty__image__logo"
              src={lap}
              alt=""
            />
          </div>
          <div className="loanAgainstProperty__heading">
            <div className="loanAgainstProperty__heading__pl">
              <h2 className="loanAgainstProperty__heading__title">
                What Is Loan Against Property ?
              </h2>
              <p className="loanAgainstProperty__heading__texts">
                Loan against property(LAP), are basically loans provided by
                banks against the security of one’ s own property.LAP is
                designed to meet the financial needs of someone who already owns
                a house or multiple properties so as to get the best out of
                their assets.Its important to remember that the property which
                you are putting up for your loan should be free any
                encumbrance(i.e.it is not given as security for any purpose or
                any other loan).Banks provide LAP for both Salaried as well as
                Self - Employed individuals.The rates and loan amounts differ
                based on your property and your annual income.Banks will always
                want to consider all risks, which is why while you are applying
                for your loan against property, there are certain factors the
                bank considers with respect to your property to mitigate its
                risks in giving out the loan.These factors determine your rate
                of interest, and loan amount.You can get a LAP of up to 80 % of
                the registered value of your property depending on the Bank’ s
                policy and the property type and valuation.The value of the
                property would be determined through a valuation conducted by
                the Loan Provider.Individuals apply for LAP for a variety of
                reasons.Some of the common ones are your childs wedding, loans
                for new business ventures, second homes, vacations, medical
                treatment just to name a few.
              </p>
            </div>
            <div className="loanAgainstProperty__heading__pleligibility">
              <h2 className="loanAgainstProperty__heading__title">
                How To Apply For Loan Against Property
              </h2>
              <div className="loanAgainstProperty__heading__texts">
                <ul>
                  <li> Application </li> 
                  <li> Processing </li>
                  <li> Documentation </li>
                  <li> Property Verification / Valuation </li>
                  <li> Sanctioning of the Loan </li> 
                  <li> Disbursement </li>
                </ul>
              </div>
            </div>
            <div className="loanAgainstProperty__heading__plamount">
              <h2 className="loanAgainstProperty__heading__title">
                Important Questions on Loan Against Property
              </h2>
              <h4 className="questions one" >
                What kind of processing fee do you need to pay for Loan Against
                Property?
              </h4>
              <p className="loanAgainstProperty__heading__texts">
                A nominal fees and charges are to be paid to the Bank depending
                upon their term and conditions.
              </p>
              <h4 className="questions one" >How much time does the bank take to disburse the loan?</h4>
              <p className="loanAgainstProperty__heading__texts">
                The processing of the loans usually takes 7 to 10 working days
                once all the documents are submitted. It also depends upon your
                profile and documentation.
              </p>
              <h4 className="questions one" >How is interest charged on Loan Against Property?</h4>
              <p className="loanAgainstProperty__heading__texts">
                Some financial institutions make LAP available only under the
                floating rate. Fixed rate loans are off limits. Borrowers need
                to enquire before finalising a LAP from lending institutions. It
                is normally available for residential properties only, but can
                be available for commercial property also.
              </p>
              <h4 className="questions one" >Does the property have to be insured?</h4>
              <p className="loanAgainstProperty__heading__texts">
                Yes the property has to be insured against fire, flood,
                earthquakes and other appropriate hazards during the tenor of
                the loan.
              </p>
              <h4 className="questions one" >How can I repay my loan?</h4>
              <p className="loanAgainstProperty__heading__texts">
                The repayment of loan is done through Equated Monthly
                Instalments. It can be paid through Post Dated Cheques (PDC) or
                Electronic Clearance System (ECS).
              </p>
              <h4 className="questions one" >Can I pre-pay my loan?</h4>
              <p className="loanAgainstProperty__heading__texts">
                The loan against property can be pre-paid along with the
                pre-payment charges. Usually the bank charges 2% of the
                principal pre-paid.
              </p>
            </div>
          </div>
        </div>
        <div className="getintouch">
        <h1 className="getintouch__title" data-aos='fade-up'>Take your step towards your financial goals</h1>
        <Link to="/BasicDetail" className="header__link">
        <button className="getintouch__button" data-aos='fade-up'>Get In Touch</button>
        </Link>
      </div>
        <Footer />
      </div>
    </div>
  );
}

export default LoanAgainstProperty;
