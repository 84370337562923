import React from "react";
import Footer from "./Footer";
import logo from "../Media/Logo.svg";
import "./Privacypolicy.css";
import Header2 from "./Header2";
import { Link } from "react-router-dom";
function Privacypolicy() {
  return (
    <>
    <div className="mobile__header">
      <Header2 />
    </div>
     
      <div className="policy">
        <div className="policy__content">
          <div className="policy__content__logo">
            <img className="policy__logo" src={logo} alt="" />
            <Link to="/Home" className="header__link">
            <span className="policy__title"> ARUSH FINSERV </span>
            </Link>
          </div>
          <h2 className="policy__content__heading">Privacy Policy</h2>
          <p className="content__text">
            Arush Finserv offers online platform/marketplace for offering
            financial products and services to you through our partnered Banks
            and NBFC.
            </p>
            <p className="content__text">
              Arush Finserv (hereinafter referred to as “Arushfinserv.in”)
              understands the privacy and confidentiality of its customers who
              have shared their personal information with us. It's our topmost
              priority to keep the information secure and avoid misuse of
              customer's personal details.
            </p>
            <p className="content__text">
            Please find below the type of information we may collect from you
            and it's usage for the services offered to you via our website and
            our business partners. This privacy policy applies to our current
            and existing, and future customers. When you register with this
            Website, you agree that we or any of our affiliate/ group
            companies/subsidiaries may contact you from time to time to provide
            the offers/ information of such products/ services.
            </p>
            <p className="content__text">
              By registering online at Arush Finserv, you allow Arush Finserv
              and its business partners to get in touch with you via phone call,
              email or SMS. The purpose of contacting you is to offer you our
              services for the product you have chosen, informing you about our
              new products, tell you about promotional offers running at Arush
              Finserv, or by its business partners and linked third parties.
            </p>
            <p className="content__text">
              Notwithstanding whether your telephone/mobile is registered with
              NDNC and or notwithstanding the Telecom Regulatory Authority of
              India [TRAI] restrictions/guidelines on unsolicited telecalls, by
              your accessing this Site fully or in an incomplete manner or by
              enquiring/verifying any of the products/services offered by Arush
              Finserv and or your making a request to call back, it is deemed
              that you have expressly authorized Arush Finserv to call back or
              send you text messages or Chatbots for solicitation and
              procurement of various products and services of Arush Finserv. In
              this regard you shall not have any complaint nor complain to TRAI
              as to any alleged unsolicited calls by Arush Finserv to you.
            </p>
        </div>
        <div className="personalinfo">
          <h3 className="personalinfo__heading">Personal Information</h3>
          <p className="content__text">
            Arush Finserv collects personal information from its customers,
            including first name, last name, email address, residence city and
            mobile number etc. Arush Finserv can also extract information from
            third parties or public shared profiles public in your social
            accounts. The collected information is stored at Arush Finserv only.
          </p>
          <h3 className="personalinfo__heading">Purpose</h3>
          <p className="content__text">
            Arush Finserv receives and store information from you when you
            register online or need some information regarding our products and
            services and visit our website. This information is provided to us
            with your explicit consent. After registering with us or sharing
            personal details with us, you authorize us to contact you via email,
            call or sms and send you promotional offers.
          </p>
          <h3 className="personalinfo__heading">Use of Infromation</h3>
          <p className="content__text">
            Arush Finserv need your personal information for the following
            purposes –
          </p>
          <ul className="content__list">
            <li>To reply to your queries submitted by you</li>
            <li>Process applications or orders submitted by you</li>
            <li>To resolve problems with any services provided to you</li>
            <li>
              To send you promotional offers, new products or services available
              on our website or third parties with whom we have a tie-up
            </li>
            <li>
              Manage or achieve our duties subject to any contract with our
              business partners
            </li>
            <li>
              Information received from you is combined with the information we
              get from third parties to make our website and services better
            </li>
            <li>As mentioned in this privacy policy</li>
          </ul>
        </div>
        <div className="personalinfo">
          <h3 className="personalinfo__heading">Infromation Disclosure and Sharing</h3>
          <p className="content__text">
            Arush Finserv does not sell/ rent or disclose your personal
            information with anyone as described in this policy. We may share
            your personal information with our business partners or with another
            entity in case of a business merger or sale of Arush Finserv
            business part. We restrict the collection and use of your personal
            information. We may disclose your personal information in a
            non-personally identifiable manner that does not reveal your
            identity. Arush Finserv does not let anyone use your account
            information and any other personal identifiable information except
            some specific domestic procedures to manage, expand and improve our
            services. Third parties who have a tie-up with us can contact you
            and tell you about our services but with limited access. They are
            required to maintain the confidentiality of the information, as
            mentioned in the contract with us.
          </p>
            <p className="content__text">
              We may share your personal information with government agencies
              under the law in case of identity verification, investigation of
              cyber incidents, hearings and punishment of offences, or
              disclosure of information required for legal purpose. We may also
              need to disclose the information to third parties under the law.
              Arush Finserv has various types of lending products offered by
              Banks and NBFC. If you apply for these products or services and
              disclose information to these providers, then the use of your
              information is dependent on their privacy policies, for which
              Arush Finserv is not responsible.
            </p>
          <h3 className="personalinfo__heading">Tracking via Cookies</h3>
          <p className="content__text">
            Arush Finserv and its partners use cookies to analyze trends, manage
            the website, improve marketing, track user's preferences around the
            website and collect demographic figures for overall use. You can
            control the use of cookies at an individual browser stage by
            disabling these cookies that may restrict your use of certain
            features or services on our website.
          </p>
          <h3 className="personalinfo__heading">Log Files</h3>
          <p className="content__text">
            We use log files that include internet protocol (IP) address,
            internet service provider (ISP), browser type, exit or referred
            pages, date/time stamp, operating system to analyze trends and
            user's movements while visiting the website. This information is
            combined with the other information we collect about improving our
            services, site functionality and marketing.
          </p>
          <h3 className="personalinfo__heading">Email - Opt - Out</h3>
          <p className="content__text">
            If you don't want us to send you emails or other promotional
            information, write to us at <strong>
            <a href="https://mail.google.com/mail/u/0/#sent?compose=GTvVlcRzCpQwbXbngjRcVjvxJJJqstFmbjXDRDvrCKHcqhxmZjBxQPZTxTBbKFWrdHQzMqSJGGCXr" alt="">info@arushfinserv.in</a></strong>
            . It may take some time to process the request, but you won't receive
            emails or any other information from us once we unsubscribe you.
          </p>
          <h3 className="personalinfo__heading">Security</h3>
          <p className="content__text">
            Arush Finserv use suitable technical and electronic measures to
            protect the personal information being submitted to us. We cannot
            guarantee complete security as no electronic system is 100% secure
            in today's time. However, we still use multiple security measures to
            protect illegal use, damage or loss of any information. You must
            also maintain your login id and password's confidentiality and
            security and may not share these credentials with any third party.
          </p>
          <h3 className="personalinfo__heading">Connection to other websites</h3>
          <p className="content__text">
            Arush Finserv provides links to other websites. The privacy of the
            information provided to other websites is not our liability. Arush
            Finserv is not responsible for any loss of personal information
            through other sources. We can only suggest you read their privacy
            policies when you visit their websites.
          </p>
          <h3 className="personalinfo__heading">
            Contact Us with Questions or Concerns or For Grievance Redressal
          </h3>
          <p className="content__text">
            If you have grievance or complaint, questions, comments, concerns,
            or feedback about the processing of information or regarding this
            Privacy and Security Policy or any other privacy or security
            concern, send an email to    
            <strong>
            <a href="https://mail.google.com/mail/u/0/#sent?compose=GTvVlcRzCpQwbXbngjRcVjvxJJJqstFmbjXDRDvrCKHcqhxmZjBxQPZTxTBbKFWrdHQzMqSJGGCXr" alt="">info@arushfinserv.in</a></strong>
            . <br /><span>
            Due to changes in legislation or enhancements to functionality and
            content on the Site, we may make changes to privacy policy (without
            being obliged to do so) and would reflect those changes in this
            privacy policy statement. Hence you are requested to go through the
            privacy policy statement on a regular basis.
            <br /></span>
            Please note that this privacy policy does not create any contractual
            or other legal rights in or on behalf of any party, nor is it
            intended to do so.
            <br /><span>
            By agreeing to avail the service offered by Arush Finserv, you have
            agreed to the collection and use of your Sensitive Personal Data or
            Information by Arush Finserv. You always have the right to refuse or
            withdraw your consent to share/dissemination of your Sensitive
            Personal Data or Information by emailing us at  </span> 
              <strong>
            <a href="https://mail.google.com/mail/u/0/#sent?compose=GTvVlcRzCpQwbXbngjRcVjvxJJJqstFmbjXDRDvrCKHcqhxmZjBxQPZTxTBbKFWrdHQzMqSJGGCXr" alt="">info@arushfinserv.in</a></strong>
            . However, in such an event, you would no longer avail the services
            of Arush Finserv.
          </p>
          <h3 className="personalinfo__heading">Notice</h3>
          <p className="content__text">
            The effective date of this Policy, as stated below, indicates the
            last time this Policy was revised or materially changed.
          </p>
          <p className="content__text">
            Effective Date</p> 
          <p className="content__text">
            August 20, 2021
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacypolicy;
