import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../Media/Logo.svg";
import linkedin from "../Media/linkedin.svg"
import LocationOnIcon from '@material-ui/icons/LocationOn';
function Footer() {
  return (
    <div className="section">
    <div className="footer">
      <div className="footer__left">
        <div className="footer__Title">
          <Link to="/" className="footer__link">
            <img className="footer__logo" src={logo} alt="" />
          </Link>
          <p className="footer__title"> ARUSH FINSERV </p>
        </div>
        <div className="footer__text">
        At Arush Finserv, we strive to provide simple, fast and hassle-free services to our customer loan requirements.
        </div>
        <div className="location">
        <LocationOnIcon className="icon1"/>
        <div className="location">
             Ground Floor, 2/81-82,LALITA PARK,LAXMI NAGAR, <br />East Delhi,
            Delhi, 110092
        </div>
        </div>
      </div>
      <div className="footer__right">
        <div className="footer__right__top">
        <div className="contactus">
          <h3 className="conactsus__heading">Contact Us</h3>
          <p className="contactus__texts">T - 011-4752-1888</p>
          <a href="https://mail.google.com/mail/u/0/#sent?compose=GTvVlcRzCpQwbXbngjRcVjvxJJJqstFmbjXDRDvrCKHcqhxmZjBxQPZTxTBbKFWrdHQzMqSJGGCXr" alt="">info@arushfinserv.in</a>
        </div>
        <div className="loans__footer">
          <h3 className="loans__new1">Loans</h3>
          <Link to="/PersonalLoan">
          <p className="loans__new">Personal Loan</p>
          </Link>
          <Link to="/BusinessLoan">
          <p className="loans__new">Business Loan</p>
          </Link>
          <Link to="/ProfessionalLoan">
          <p className="loans__new">Professional Loan</p>
          </Link>
          <Link to="/LoanAgainstProperty">
          <p className="loans__new">Loan Against Property</p>
          </Link>
        </div>
         </div>
        <div className="footer__new">
        <div className="legal">
          <Link to="/Privacypolicy" className="plink">
          <p className="legal__texts">Privacy Policy</p>
          </Link>
        </div>
        <div className="company">
          <Link to="/Partner" className="plink">
          <p className="legal__texts">Become a Partner</p>
          </Link>
        </div>
        <div className="social">
        <Link to={{ pathname: "https://www.linkedin.com/company/arush-finserv" }} target="_blank" className="footer__link">
        <img className="footer__logo s2 legal__texts" src={linkedin} alt="" />
        </Link>
        </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Footer;
