import React from 'react'
import calc from "../Media/Calculation.svg"
import "./BasicDetail.css"
function BasicDetail() {
  return (
    <div className="basicDetail">
      <img
          className="basicDetail__image"
          src={calc}
          alt=""
          data-aos="fade-right"
        />
        <form data-aos="fade-left">
          <div className="basic__details">
          <h2 className="text">Basic Details</h2>
            <h4 className="head">Full Name</h4>
            <input type="text" className="fullName" placeholder="e.g. Enter your full name" required />
            <h4 className="head">E-Mail</h4>
            <input type="email" className="fullName" placeholder="e.g. johnsmith@xmail.com" required />
            <h4 className="head">Type of Loan</h4>
            <select name="Personal Loan" id="loans" required >
                <option value="Personal Loan">Personal Loan</option>
                <option value="Business Loan">Business Loan</option>
                <option value="Loan Against Property">Loan Against Property</option>
                <option value="CA/Doctor Loan">Professional Loan</option>
            </select>
            <h4 className="head">Required Amount</h4>
            <input type="number" className="fullName nb" placeholder="e.g. 500000" min="500000" max="10000000" minLength="6" maxLength="8" required />
            <h4 className="head">Mobile Number</h4>
            <div className="mobile__number">
              <span className="code">+91</span>
            <input type="number" className="phone__number fullName"   required/>
            </div>
          </div>
          <button className="basicButton">Get In Touch</button>
        </form>
    </div>
  )
}

export default BasicDetail
