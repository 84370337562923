import React from "react";
import "./Testimonials.css"
function Testimonials() {
  return (
    <div className="testimonials">
      <div className="testimonials__title">
        <h2 className="testimonials__title__heading">Testimonials</h2>
        <p className="testimonials__title__subhead">
          What customers says about us
        </p>
      </div>
      <div className="testimonials__card">
        <div className="testimonials__card__left">
          <div className="testimonials__card__left__card1">
            <p className="testimonials__card__left__card1__text">
            The co-operation of the team was wonderful and each time I call with lot of queries, all get answered quickly 
            </p>
            <p className="testimonials__card__left__card1__subtext">CA Ashish Jindal</p>
            <p className="testimonials__card__left__card1__subtext__foot">Manager at Hindustan Unilever</p>
          </div>
          <div className="testimonials__card__left__card1">
            <p className="testimonials__card__left__card1__text">
              Had a very good experience with Arush Finserv. Their process is very quick and smooth.           </p>
            <p className="testimonials__card__left__card1__subtext">Kumar Anoop</p>
            <p className="testimonials__card__left__card1__subtext__foot">Self Employed</p>
          </div>
        </div>
        <div className="testimonials__card__middle">
          <div className="testimonials__card__left__card1 m2">
            <p className="testimonials__card__left__card1__text">
              Very supportive team with clear understanding of product. Complete hand holding from applcation to disbursal
            </p>
            <p className="testimonials__card__left__card1__subtext">Adarsh Aggarwal</p>
            <p className="testimonials__card__left__card1__subtext__foot">Owner at Kusum Ecomm</p>
          </div>
          <div className="testimonials__card__left__card1 m2">
            <p className="testimonials__card__left__card1__text">
              Wonderful team who guided me through each process of loan application to fulfill my funds need
            </p>
            <p className="testimonials__card__left__card1__subtext">Praneet Ghildyal</p>
            <p className="testimonials__card__left__card1__subtext__foot">Owner at Arrow Design</p>
          </div>
        </div>
        <div className="testimonials__card__right">
          <div className="testimonials__card__left__card1">
            <p className="testimonials__card__left__card1__text">
              Focussed team who deals with honesty in explaining the product and its benefits. Thanks for your support and service
            </p>
            <p className="testimonials__card__left__card1__subtext">Shaik Shakiriya</p>
            <p className="testimonials__card__left__card1__subtext__foot">Software developer at Oracle</p>
          </div>
          <div className="testimonials__card__left__card1">
            <p className="testimonials__card__left__card1__text">
              Very transparent process. They fulfilled my requirement of Overdraft. Whole process was completed within 24 hours
            </p>
            <p className="testimonials__card__left__card1__subtext">Sakshi Sinha</p>
            <p className="testimonials__card__left__card1__subtext__foot">Manager at R S </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
