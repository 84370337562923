import React, { useEffect } from "react";
import Header2 from "./Header2";
import Footer from "./Footer";
import bsnloan from "../Media/Business Loan.svg";
import "./Businessloan.css";
import { Link } from "react-router-dom";
function Businessloan() {
  useEffect(() => {
    return window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header2 />
      <div className="businessLoan">
        <div className="businessLoan__image"
        data-aos="slide-left">
          <div className="businessLoan__image__heading">
            <h1 className="businessLoan__image__heading__title">
              Expand your business with loans at lowest interest rate or
              overdraft facility for working capital requirements
            </h1>
          </div>
          <img className="businessLoan__image__logo" src={bsnloan} alt="" />
        </div>

        <div className="businessLoan__heading">
          <div className="businessLoan__heading__pl">
            <h2 className="businessLoan__heading__title">Business Loans</h2>
            <div className="businessLoan__heading__texts">
              Financial Flow is the life blood of any successful organisation.
              Most businesses will need a large amount of working capital to
              start up a new business or enable the rapid growth of an existing
              business. Scaling up always requires a influx of finances. Which
              is why, we go to all lengths to ensure that we can disburse a loan
              that can help you take your business to the next level. Business
              loans are very special to Arush Finserv and we have been helping
              our customers scale to new heights of success by helping them
              finance their dreams for their enterprises. While a personal is
              given on a personal capacity and income, a business loan is given
              based on company’s income and capacity and is given in the
              company’s name. Business loans are categorized broadly into
              following 2 segments.
              <ol className="business__list">
                <li>
                  Unsecured business loans – 3 year term loan for
                  proprietorship, partnership, private limited companies and
                  limited companies based on a sound balance sheet.
                </li>
                <li>
                  Secured business loans – Working capital funding in rupees as
                  well as foreign currency for proprietorship, partnership,
                  private limited companies and limited companies
                </li>
              </ol>
            </div>
          </div>
          <div className="businessLoan__heading__pleligibility">
            <h2 className="businessLoan__heading__title">
              Business Loan Eligibility Criteria
            </h2>
            <p className="businessLoan__heading__texts">
              Most banks have a common set of parameters that they evaluate loan
              applicats with, but what exactly these parameters are depends from
              bank to bank.
            </p>
            <h2 className="businessLoan__heading__title">Age</h2>
            <p className="businessLoan__heading__texts">
              Most banks will disburse business loans to individuals aged
              between 24 to 65 years.
            </p>
          </div>

          <div className="businessLoan__heading__plamount">
            <h2 className="businessLoan__heading__title">
              Current Business Experience
            </h2>
            <p className="businessLoan__heading__texts">
              Banks will prefer that your business has been showing stable signs
              of growth at least for the last 3 years. If you can show sustained
              billing and revenues and top it off with profit, getting a
              business loan should not be a problem for you. Some banks give a
              loan amount up to 60 to 80% of your median annual profits.
            </p>
          </div>
          <div className="businessLoan__heading__plir">
            <h2 className="businessLoan__heading__title">
              Total Business Experience
            </h2>
            <p className="businessLoan__heading__texts">
              A bank will prefer if you have been engaged in sustainable
              business for atleast 5 years to consider giving you a loan. Your
              diversity in terms of business experience and so on along with
              good results will help you acquire your business loan.
            </p>
          </div>
          <div className="businessLoan__heading__plfast">
            <h2 className="businessLoan__heading__title">Documents Required</h2>
            <div className="businessLoan__heading__texts">
              <ul>
                <li>Identity Proof</li>
                <li>Address Proof</li>
                <li>Bank Statement (Latest 6 months)</li>
                <li>
                  Latest ITR along with other relevant docs like Balance Sheet,
                  P&L, Audit Report (Certified by a CA)
                </li>
              </ul>
            </div>
          </div>
          <div className="businessLoan__heading__plcoapp">
            <h2 className="businessLoan__heading__title">Tenure</h2>
            <p className="businessLoan__heading__texts">
              Business loans range from min. of 1 year up to a max of 4 years
            </p>
          </div>
          <div className="businessLoan__heading__plcoapp">
            <h2 className="businessLoan__heading__title">Processing Time</h2>
            <p className="businessLoan__heading__texts">
              The processing of the loans usually takes 4 working days after all
              the documents are submitted.
            </p>
          </div>
          <div className="businessLoan__heading__plcoapp">
            <h2 className="businessLoan__heading__title">Pre-Payment</h2>
            <p className="businessLoan__heading__texts">
              You can pre-pay the loan after repaying a min. of 6 EMI’s.
              Foreclosure charges would be applicable as per the banks term and
              condition.You can make a part pre-payment with a minimum amount of
              Rs.50,000 after 6 months from the date of disbursement of the
              loan. This part pre-payment can be made only once in a financial
              year.
            </p>
          </div>
        </div>
      </div>
      <div className="getintouch">
        <h1 className="getintouch__title" data-aos='fade-up'>Take your step towards your financial goals</h1>
        <Link to="/BasicDetail" className="header__link">
        <button className="getintouch__button" data-aos='fade-up'>Get In Touch</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Businessloan;
