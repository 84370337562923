import React from 'react'
import "./Card.css"
import { Link } from "react-router-dom";
function Card(props) {
  return (
    <div className={props.margin?"card m3": "card"} data-aos={props.animate}>
      <div className="card__title">
        <h3>{props.title}</h3>
      </div>
      <div className="card__text">
        <span>{props.text}</span>
      </div>
      <Link to={props.link}>
        <button className="card__button">
            Check Now
        </button>
      </Link>
    
    </div>
  )
}

export default Card
