import React, { useState } from "react";
import "./LoanCalculator.css";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";


function LoanCalculator() {

  const [numValue, setNumvalue] = useState(5);
  const [monthValue, setMonthvalue] = useState(3);
  const [barValue, setBarvalue] = useState(500000);
  const [paymentValue, setPayment] = useState(0);
  const [perror, setpError] = useState(false);
  const [yerror, setyError] = useState(false);
  function submit(e) {
    e.preventDefault()
    if(numValue > 50 || numValue < 2 || isNaN(numValue)){
      return setpError(true)
    }
    else if(monthValue < 1 || monthValue > 25 || isNaN(monthValue)){
      return setyError(true)
    }
    setpError(false)
    setyError(false)
    var principal = parseFloat(barValue);
    var interest = parseFloat(numValue) / 100 / 12;
    var payments = parseFloat(monthValue)*12;
    var x = Math.pow(1 + interest, payments);
    var monthly = (principal*x*interest)/(x-1);
    setPayment(monthly.toFixed(2));
  }
  function mincrease() {
    let initial = 0;
    let month = isNaN(parseInt(monthValue))?initial + 1:parseInt(monthValue) + 1;
    setMonthvalue(month)
  }
  function mdecrease() {
    let initial = 0;
    let month = isNaN(parseInt(monthValue))?initial - 1:parseInt(monthValue) - 1;
    setMonthvalue(month)
  }
  function increase() {
    let initial = 0;
    let newnum = isNaN(parseInt(numValue))?initial + 1:parseInt(numValue) + 1;
    setNumvalue(newnum);
  }
  function decrease() {
    let initial = 0;
    let newnum = isNaN(parseInt(numValue))?initial - 1:parseInt(numValue) - 1;
    setNumvalue(newnum);
  }
  return (
    <>
    <form data-aos="fade-right" id="form">
      <div className="loanCalculator">
        <p>
          <strong>LOAN AMOUNT</strong>
        </p>
        <div className="loanCalculator__amount">
          <input
            className="barvalue"
            type="text"
            value={barValue}
            onChange={(event) => setBarvalue(event.target.value)}
            minLength="6"
            maxLength="8"
            max="10000000"
            required
          />
        </div>
        <input
          className="barvalue__max"
          type="range"
          value={barValue}
          min="500000"
          max="10000000"
          onChange={(event) => setBarvalue(parseInt(event.target.value))}
          required
        />
        <p>
          <strong>ROI</strong>
        </p>
        <div className="loanCalculator__percent">
          <div className="value-button" id="decrease" onClick={decrease}>
            <RemoveCircleOutlineIcon />
          </div>
          <span className="perInput">
            <input
              className="numValue__event"
              type="number"
              id="number"
              value={numValue}
              onChange={(event) => setNumvalue(event.target.value)}
              min="2"
              max="50"
              required
            />
          </span>
          <div className="value-button" id="increase" onClick={increase}>
            <ControlPointIcon />
          </div>
        </div>
        <span className={perror ?"perror error": "perror"}>Value must be between 2% to 50%</span>
        <p>
          <strong>Year(s)</strong>
        </p>
        <div className="loanCalculator__percent">
          <div className="value-button" id="decrease" onClick={mdecrease}>
            <RemoveCircleOutlineIcon />
          </div>
          <input
            className="month__value"
            type="number"
            id="number"
            value={monthValue}
            onChange={(event) => setMonthvalue(event.target.value)}
            min="1"
            max="25"
            required
          />
          <div className="value-button" id="increase" onClick={mincrease}>
            <ControlPointIcon />
          </div>
        </div>
        <span className={yerror ?"yerror error": "yerror"}>Value must be between 1 to 25</span>
        <p>
          <strong>EMI</strong>
        </p>
        <div className="loanCalculator__payment">
          <input
            className="emi__value"
            type="text"
            id="number"
            onChange={(event) => setPayment(event.target.value)}
            value={paymentValue}
          />
        </div>
      </div>
      <button type="submit" className="submitButton" onClick={submit} >
        Check Now
      </button>
    </form>
    
    </>
  );
}

export default LoanCalculator;
