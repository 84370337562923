import React from 'react'
import "./NeedUs.css"
import needhelp from "../Media/needhelp.svg"
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
function NeedUs() {
  return (
    <div className="needus">
      <div className="needus__box" data-aos="fade-right">
        <h2 className="needus__box__TITLE">Need Help ?</h2>
        <img className="needus__box__image" src={needhelp} alt="" />
        <p className="needus__box__text">Have confusing on your running loan? Talk to us and we will help you navigate through your loan sanction letter and agreement terms. Its free.</p>
        <a href="tel:011-4752-1888" alt="" className="iconsWhole" ><PhoneRoundedIcon className="icon" /> Call Us</a>
        <a href="https://api.whatsapp.com/send?phone=918375086154" alt="" className="iconsWholes" ><PhoneRoundedIcon className="icon" /> Whatsapp Us</a>
        <a href="https://mail.google.com/mail/u/0/#sent?compose=GTvVlcRzCpQwbXbngjRcVjvxJJJqstFmbjXDRDvrCKHcqhxmZjBxQPZTxTBbKFWrdHQzMqSJGGCXr" alt="" className="iconsWhole" ><MailOutlineIcon className="icons" /> EMail Us</a>
      </div>
      <div className="needus__text">
        <h2 className="needus__text__head">Let’s make lending ecosystem more understandable.</h2>
        <h4 className="needus__text__subtext">Simplify complex jargons and numbers on your loan agreements. Understand your needs and make better decision while applying for your next loan. </h4>
        <ul className="needus__text__list">
          <li>Unbiased Advice</li>
          <li>Simplified understanding</li>
          <li>Personalized suggestion</li>
        </ul>
      </div>
    </div>
  )
}

export default NeedUs
