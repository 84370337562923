import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css"
import logo from "../Media/Logo.svg"
import MenuIcon from '@material-ui/icons/Menu';
import { scroller } from "react-scroll";

function Header() {

  function scrollToSection()  {
    scroller.scrollTo("home__loans__card", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  function scrollTobasic()  {
    scroller.scrollTo("basicDetail", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  // function scrollto(){
  //   window.scrollTo({
  //     top: 643,
  //     behavior: 'smooth',
  //   }); 
  // }
  function respo() {
    if(hamburger){
      setHamburger(false);
    }else {
      setHamburger(true);
    }
  }
  const [hamburger, setHamburger] = useState(true);
  return (
    <nav className="header">
      <Link to="/" className="header__link" data-aos="slide-right">
        <img className="header__logo" src={logo} alt="" />
        <span className="header__title"> ARUSH FINSERV </span> 
      </Link>
      
      <div className={hamburger ?"header__nav ":"header__nav headmenu" }>
        <Link to="/" className="header__link">
          <div className="header__option" data-aos="slide-left">
          <span><strong>HOME</strong></span>
          </div>
        </Link>
        <div onClick={scrollToSection} className="header__option hand" data-aos="slide-left">
          <span><strong>LOAN</strong></span>
          </div>
        <Link to="/contactus" className="header__link">
        <div className="header__option" data-aos="slide-left">
          <span><strong>CONTACT US</strong></span>
          </div>
        </Link>
        <Link to="/Partner" className="header__link">
        <div className="header__option" data-aos="slide-left">
          <span><strong>BECOME A PARTNER</strong></span>
          </div>
        </Link>
        <div className="header__option" data-aos="slide-left">
          <button className="header__button" onClick={scrollTobasic} ><strong>Apply Now</strong></button> 
        
        </div>
      </div>
      <div className="header__option menu" onClick={respo}>
          <MenuIcon />
      </div>
    </nav>
  );
}

export default Header;
