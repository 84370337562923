import React,{useEffect} from "react";
import Header2 from "./Header2";
import Footer from "./Footer";
import bsnloan from "../Media/Professional Loan.svg";
import "./ProfessionalLoan.css";
import { Link } from "react-router-dom";
function ProfessionalLoan() {
  useEffect(()=>{
    return window.scrollTo(0,0)
  })
  return (
    <div>
      <Header2 />
      <div className="professionalLoan">
        <div className="professionalLoan__image"
        data-aos="slide-left">
          <div className="professionalLoan__image__heading">
            <h1 className="professionalLoan__image__heading__title">
            Avail Maximum loan / overdraft facility on behalf of your degree at attractive interest rate.
            </h1>
          </div>
          <img className="professionalLoan__image__logo" src={bsnloan} alt="" />
        </div>

        <div className="professionalLoan__heading">
          <div className="professionalLoan__heading__pl">
            <h2 className="professionalLoan__heading__title">
              Professional Loan:
            </h2>
            <p className="professionalLoan__heading__texts">
              Professional Loans are loans which self-employed people like
              doctors, chartered accountants, company secretary etc. can avail
              without any collateral in order to upgrade business or purchasing
              equipment. Professional Loans are designed keeping in mind the
              diverse professional and financial commitments and are offered
              with simple eligibility criteria, minimal documentation and quick
              disbursal of funds. There are various banks in India who offer
              professional loans to customers.
            </p>
          </div>
          <div className="professionalLoan__heading__pleligibility">
            <h2 className="professionalLoan__heading__title">
              Professional Loan Eligibility Criteria:
            </h2>
            <div className="professionalLoan__heading__texts">
              There are certain eligibility criteria you will have to fulfill in
              order to avail a professional loan which are given below:
              <ul className="professional__list">
                <li>Should be a resident of India</li>
                <li>
                  Minimum age of eligibility should be 25 years and maximum age
                  of eligibility should be 65 years
                </li>
                <li>
                  Doctors, Chartered Accountants, professionals, and
                  non-professionals can apply for a professional loan
                </li>
                <li>Minimum income should be Rs.1 lakh per annum</li>
                <li>
                  Business must be making profit for at least 2 previous years
                </li>
              </ul>
            </div>
          </div>

          <div className="professionalLoan__heading__plamount">
            <h2 className="professionalLoan__heading__title">
              Professional Loan Amount:
            </h2>
            <p className="professionalLoan__heading__texts">
              Based on the degree and obligation, a professional can avail loan
              ranging from 5 Lakh to 75 lakhs. Tenure of loan can vary between 3
              to 8 years based on banks policy and customer requirements.
            </p>
          </div>
          <div className="professionalLoan__heading__plir">
            <h2 className="professionalLoan__heading__title">
              Documents required
            </h2>
            <div className="professionalLoan__heading__texts">
              Salaried Professionals
              <ul className="professional__list">
                <li>
                  Photo identity proof, address proof: As per RBI defined KYC
                  guidelines.
                </li>
                <li>
                  Education Proof- Degree certificate, Degree registration
                </li>
                <li>3 Months Bank Statement</li>
                <li>Employee ID Card</li>
                <li>Residence Ownership proof</li>
              </ul>
            </div>
            <div className="professionalLoan__heading__texts">
              Self Employed professionals and Entities such as Hospitals,
              Diagnostic centers
              <ul className="professional__list">
                <li>
                  Photo identity proof, address proof: As per RBI defined KYC
                  guidelines.
                </li>
                <li>
                  Education Proof- Degree certificate, Degree registration
                </li>
                <li>Ownership proof of residence or office as applicable</li>
                <li>
                  Business Proof: Shop Act License/MOA & AOA/GST
                  registration/ITR, Partnership Deed.
                </li>
              </ul>
            </div>
            <div className="professionalLoan__heading__texts">
              Any one of the additional documents as per program
              <ul className="professional__list">
                <li>
                  Basis Banking - 1 year ITR & Computation, 12 months banking
                </li>
                <li>Or Basis Degree - 3 months banking</li>
                <li>
                  Or Basis Income - 2 years ITR & financials, 12 months banking
                </li>
              </ul>
            </div>
          </div>
          <div className="professionalLoan__heading__plfast">
            <h2 className="professionalLoan__heading__title">
              Professional Loans FAQs
            </h2>
            <div className="professionalLoan__heading__texts">
              <h4>1. Who can apply for a Professional Loan?</h4>
              <ul className="professional__list">
                <li>Graduate Allopathic Doctors</li>
                <li>Post Graduate Allopathic Doctors</li>
                <li>BDS/ MDS</li>
                <li>Veterinary Doctor</li>
                <li>Physiotherapists</li>
                <li>BAMS, BHMS</li>
                <li>Diagnostic Centres & Hospitals</li>
                <li>CA, CS, Architects</li>
                <li>Consulting Engineers</li>
              </ul>
            </div>
            <div className="professionalLoan__heading__texts">
              <h4>2. How can I apply for a professional loan?</h4>
              <ul className="professional__list">
                <li className="no_list">
                  You can apply for a professional loan online by visiting the
                  official website of the bank and clicking on the ‘Apply Now’
                  button. You will have to provide required details and submit
                  the necessary documents. You will also have to choose the loan
                  amount you wish to avail. Once you have submitted the
                  documents, the bank will review and verify the documents and
                  if they are found to be correct, then the loan amount will be
                  disbursed to your bank account.
                  <br /> You can also apply for a professional loan offline by
                  visiting the nearest branch of the lender from whom you wish
                  to avail this loan. A representative from the bank will help
                  you and take you through the process of applying for a
                  professional loan. Make sure to carry all the relevant
                  documents with you.
                </li>
              </ul>
            </div>
            <div className="professionalLoan__heading__texts">
              <h4>3. Where can I use the EMI calculator in order to decide the loan
              amount I must avail?</h4>
              <ul className="professional__list">
                <li className="no_list">
                  Various banks have the EMI Calculator tool on their official
                  website which you can use. You will have to enter the loan
                  amount you wish to avail, repayment tenure, and the interest
                  rate and click on ‘Calculate’. You will be able to view the
                  EMI you will have to pay on a monthly basis.
                  <br />
                  You can also visit the BankBazaar website and use the EMI
                  Calculator available. Enter the loan amount, repayment tenure,
                  and interest rate and check the EMI you will pay on a monthly
                  basis.
                </li>
              </ul>
            </div>
            <div className="professionalLoan__heading__texts">
              <h4>4.	What is the mode of repayment of loan?</h4>
              <ul className="professional__list">
                <li className="no_list">
                You can repay your Loan various payment options. You can use your debit or credit card, net banking, UPI, or though NACH (National Automated Clearing House) in order to pay your EMI online. You can also visit the nearest branch of the bank from whom you have availed the loan and pay via debit card, credit card, cheque, demand draft, or simply by cash.
                </li>
              </ul>
            </div>
            <div className="professionalLoan__heading__texts">
            <h4>5.	Are there any pre-payment or foreclosure charges I will have to pay?</h4>
              <ul className="professional__list">
                <li className="no_list">
                Whether you will be required to pay any pre-payment or foreclosure charges will depend from lender to lender.
                </li>
              </ul>
            </div>
            <div className="professionalLoan__heading__texts">
            <h4>6.	Can I have a guarantor if I am applying for a professional loan?</h4>
              <ul>
                <li className="no_list">
                Yes, you can have a guarantor if you are applying for a professional loan.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="getintouch">
        <h1 className="getintouch__title" data-aos='fade-up'>Take your step towards your financial goals</h1>
        <Link to="/BasicDetail" className="header__link">
        <button className="getintouch__button" data-aos='fade-up'>Get In Touch</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default ProfessionalLoan;
