import React from "react";
import Header from "./Components/Header";
import image1 from "./Media/Headersvg.svg";
import "./Home.css";
import Scrollbar from "./Components/Scrollbar";
import p1 from "./Media/p1.svg";
import p2 from "./Media/p2.svg";
import p3 from "./Media/p3.svg";
import p4 from "./Media/p4.svg";
import whyus1 from "./Media/whyus1.svg";
import whyus2 from "./Media/whyus2.svg";
import whyus3 from "./Media/whyus3.svg";
import whyus4 from "./Media/whyus4.svg";
import hand from "./Media/hand.svg";
import LoanCalculator from "./Components/LoanCalculator";
import Title from "./Components/Title";
import Card from "./Components/Card";
import AOS from "aos";
import "aos/dist/aos.css";
import BasicDetail from "./Components/BasicDetail";
import Bank from "./Components/Bank";
import NeedUs from "./Components/NeedUs"
import Testimonials from "./Components/Testimonials"
import Footer from "./Components/Footer"
import { scroller } from "react-scroll";
AOS.init({
  offset: 120,
  duration: 1000,
  delay: 50,
  easing: "ease",
});
function Home() {
  function scrollTobasic()  {
    scroller.scrollTo("basicDetail", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
 
  const text = {
      personal_loan:
      "Apply for Personal Loan from the most reputed Banks and NBFC at attractive interest rates and flexible repayment options.Open your Over Draft account and use it as per your needs.",
      business_loan:
      "Looking for a financing operation to expand your business. Want to speed up your entrepreneurship venture. Connect with us to find the easiest way to finance you Idea at the lowest interest rate.",
      professional_loan:
      "Loan facility for working professionals like Doctors, CA, CS, Architects, Physiotherapists, Consulting Engineers etc. who are looking for funds to start their own practice and expand their trade.",
      loanAP:
      "Have requirement of fund for your personal or business use. Mortgage your residential, commercial or industrial property and find the most attractive rate available in market.",
    };
  return (
    <div className="home">
      <Header />
      <div className="home__heroImage" >
        <img
          className="home__image img-fluid rounded float-start"
          src={image1}
          alt=""
          data-aos="fade-right"
          
        />

        <LoanCalculator />
      </div>
      <div className="home__loans">
        <Title name="Loans We Provide" />
        <div className="home__loans__card">
          <Card
            title="Personal Loan"
            text={text.personal_loan}
            animate="fade-up"
            link="/PersonalLoan"
          />
          <Card
            title="Business Loan"
            margin="true"
            text={text.business_loan}
            animate="fade-up"
            link="/Businessloan"
          />
          <Card
            title="Professional Loan"
            margin="true"
            text={text.professional_loan}
            animate="fade-up"
            link="/ProfessionalLoan"
          />
          <Card
            title="Loan Against Property"
            text={text.loanAP}
            animate="fade-up"
            link="/LoanAgainstProperty"
          />
        </div>
      </div>
      <Title name="Process" />
      <div className="home__process">
        <div className="home__process__left">
          <img
            className="process__image"
            src={p1}
            alt=""
            data-aos="fade-right"
          />
          <img
            className="process__image"
            src={p4}
            alt=""
            data-aos="fade-right"
          />
          <img
            className="process__image"
            src={p2}
            alt=""
            data-aos="fade-right"
          />
          <img
            className="process__image"
            src={p3}
            alt=""
            data-aos="fade-right"
          />
        </div>
        <div className="home__process__middle">
          <Scrollbar />
        </div>
        <div className="home__process__right">
          <h2 data-aos="fade-left"> Provide Documents </h2>
          <h2 data-aos="fade-left"> Check Eligibility </h2>
          <h2 data-aos="fade-left"> Compare Offers </h2>
          <h2 data-aos="fade-left"> Get Sanctioned </h2>
        </div>
      </div>
      <div className="home__WhyUs">
        <Title name="Why Us" />
        <div className="home__WhyUs__content">
          <div className="home__WhyUs__content__left">
            <img
              className="home__WhyUs__content__image"
              src={whyus3}
              data-aos="fade-up"
              alt=""
            />
            <div className="home__WhyUs__content__text" data-aos="fade-up">
              <h2>Door Step Service</h2>
              <span className="whyus__text">
                Apply and receive funds at your ease with all documentation
                services provided at your doorstep.
              </span>
            </div>
            <img
              className="home__WhyUs__content__image"
              src={whyus1}
              data-aos="fade-up"
              alt=""
            />
            <div className="home__WhyUs__content__text" data-aos="fade-up">
              <h2>Fastest Process</h2>
              <span className="whyus__text">
                Tech enabled shortlisting of the most suitable lender. Touch
                free online service for faster execution.
              </span>
            </div>
          </div>
          <div className="home__WhyUs__content__middle">
            <img
              className="home__WhyUs__content__middle__image"
              src={hand}
              data-aos="fade-up" 
              alt=""
            />
          </div>
          <div className="home__WhyUs__content__right">
            <img
              className="home__WhyUs__content__image  m22"
              src={whyus2}
              alt=""
              data-aos="fade-up"
            />
            <div className="home__WhyUs__content__text" data-aos="fade-up">
              <h2>Transparent Processing</h2>
              <span className="whyus__text">
                No hidden charges. Understand your loan profile based on your
                document and eligibility of different banks.
              </span>
            </div>
            <img
              className="home__WhyUs__content__image"
              src={whyus4}
              alt=""
              data-aos="fade-up"
            />
            <div className="home__WhyUs__content__text" data-aos="fade-up">
              <h2>Quick Disbursement</h2>
              <span className="whyus__text">
                With dedicated relationship manager to look after your process,
                get loan 2X faster at Arush Finserv.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BasicDetail />
      </div>
      <div className="achieve" >
          <div className="loans__distribution" data-aos="fade-right">
            <h1 className="loans__distribution__text">500 Cr+</h1>
            <h4 className="loans__distribution__subtext">Loans Distributed</h4>
          </div>
        <div className="happy__customers" data-aos="fade-in">
          <h1 className="happy__customers__text">20000+</h1>
          <h4 className="happy__customers__subtext">Happy Customers</h4>
        </div>
        <div className="bank__partners" data-aos="fade-left">
            <h1 className="bank_partners__text">26+</h1>
            <h4 className="bank_partners__subtext">Banks / NBFC as Partners</h4>
          </div>
      </div>
      <Bank />
      <NeedUs />
      <Testimonials />
      <div className="getintouch">
        <h1 className="getintouch__title" data-aos='fade-up'>Take your step towards your financial goals</h1>
        <button className="getintouch__button" onClick={scrollTobasic} data-aos='fade-up' >Get In Touch</button>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
