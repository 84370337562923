import React from "react";
import Gif from "../Media/gifs/Partner.svg";
import "./Partner.css";
import Header2 from "./Header2";
import Disbursement from "../Media/Disbursement.svg";
import Lenders from "../Media/multiple lenders.svg";
import Personalassistance from "../Media/Personal assistance.svg";
import Growth from "../Media/growth.svg";
import Entreprenuer from "../Media/Entreprenuer.svg";
import ExistingAgent from "../Media/Existing agents.svg";
import HomeMakers from "../Media/Home makers.svg";
import Professionals from "../Media/Professionals.svg";
import Brokers from "../Media/Brokers.svg";
import Footer from "./Footer";
function Partner() {
  return (
    <>
      <div className="partner">
        <Header2 />
        <div className="partner__imageTag">
          <div className="partner__heading" data-aos="fade-left">
            <h1 className="partner__heading__text">
              Break Free from stereotype carrer, <br />
              Fire up the entrepreneur in you
            </h1>
            <ul>
              <li className="partner__heading__list">Fastest Disbursements</li>
              <li className="partner__heading__list">Higher Earning</li>
            </ul>
          </div>
          <img
            className="partner__image"
            src={Gif}
            alt=""
            data-aos="fade-left"
          />
        </div>
        <div className="leverage">
          <h2 className="leverage__heading">
            Leverage your network to get the extra edge and earn with Arush
            Finserv
          </h2>
          <ul className="leverage__list" data-aos="slide-right">
            <li className="leverage__lists">No need of traditional office</li>
            <li className="leverage__lists">Paperless customer onboarding</li>
            <li className="leverage__lists">
              Dealing in multiple loan product
            </li>
            <li className="leverage__lists">Dedicated relationship manager</li>
            <li className="leverage__lists">
              Track your business growth with ease
            </li>
          </ul>
        </div>
        <div className="benefits">
          <div className="benefits__heading" data-aos="fade-up">
            <h1 className="benefits__heading__text">
              BENEFITS OF BEING AN ARUSH FINSERV PARTNER
            </h1>

            <div className="benefits__images">
              <div className="benefits__image" data-aos='fade-up'>
                <img className="benefits__image__pic" src={Growth} alt="" />
                <span className="benefits__image__text">
                  High Revenue Sharing Model
                </span>
              </div>
              <div className="benefits__image" data-aos='fade-up'>
                <img className="benefits__image__pic ac"src={Lenders} alt="" />
                <span className="benefits__image__text ">
                  Access to multiple lenders
                </span>
              </div>
              <div className="benefits__image" data-aos='fade-up'>
                <img
                  className="benefits__image__pic gas"
                  src={Personalassistance}
                  alt=""
                />
                <span className="benefits__image__text">
                  Personal Assistance
                </span>
              </div>
              <div className="benefits__image" data-aos='fade-up'>
                <img
                  className="benefits__image__pic gas"
                  src={Disbursement}
                  alt=""
                />
                <span className="benefits__image__text">
                  Quick Execution & Disbursement
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="partners" data-aos="fade-up">
          <h1 className="partners__heading">WHO CAN BECOME OUR PARTNER</h1>

          <div className="partners__images">
            <div className="partners__image" data-aos='fade-up'>
              <img
                className="partners__image__pic s"
                src={Entreprenuer}
                alt=""
              />
              <span className="partners__image__text">Entrepreneur </span>
            </div>
            <div className="partners__image" data-aos='fade-up'>
              <img
                className="partners__image__pic s"
                src={ExistingAgent}
                alt=""
              />
              <span className="partners__image__text">Existing Agents</span>
            </div>
            <div className="partners__image" data-aos='fade-up'>
              <img
                className="partners__image__pic s"
                src={Professionals}
                alt=""
              />
              <span className="partners__image__text">Professionals</span>
            </div>
            <div className="partners__image" data-aos='fade-up'>
              <img className="partners__image__pic d" src={HomeMakers} alt="" />
              <span className="partners__image__text">Home Workers</span>
            </div>
            <div className="partners__image" data-aos='fade-up'>
              <img className="partners__image__pic d" src={Brokers} alt="" />
              <span className="partners__image__text">
                Brokers & Sub-Brokers
              </span>
            </div>
          </div>
        </div>
        <div className="contactusform">
          <div className="container" data-aos="fade-up">
            <div className="container__text">Join Us</div>
            <form action="#">
              <div className="form-row">
                <div className="input-data">
                  <input type="text" required />
                  <div className="underline"></div>
                  <label>First Name</label>
                </div>
                <div className="input-data">
                  <input type="text" required />
                  <div className="underline"></div>
                  <label>Last Name</label>
                </div>
              </div>
              <div className="form-row">
                <div className="input-data">
                  <input type="text" required />
                  <div className="underline"></div>
                  <label>Email Address</label>
                </div>
                <div className="input-data">
                  <input type="number" required />
                  <div className="underline"></div>
                  <label>Phone Number</label>
                </div>
              </div>
              <div className="form-row">
                <div className="input-data">
                  <input type="text" required />
                  <div className="underline"></div>
                  <label>Company Name</label>
                </div>
                <div className="input-data">
                  <input type="number" required />
                  <div className="underline"></div>
                  <label>City</label>
                </div>
              </div>
              <div className="form-row">
                <div className="input-data">
                  <label className="plabel">Profession</label>
                  <select name="Please choose an option" id="loans" required>
                    <option value="Business Loan">Wealth Advisor</option>
                    <option value="Loan Against Property">Salaried</option>
                    <option value="CA/Doctor Loan">Businessmen</option>
                    <option value="CA/Doctor Loan">Doctor</option>
                    <option value="CA/Doctor Loan">Chartered Accountant</option>
                    <option value="CA/Doctor Loan">Insurance agent</option>
                    <option value="CA/Doctor Loan">Reat Estate Agent</option>
                    <option value="CA/Doctor Loan">Others</option>
                  </select>
                </div>
              </div>
              <div className="form-row submit-btn">
                <div className="input-data">
                  <div className="inner"></div>
                  <input type="submit" value="submit" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Partner;
