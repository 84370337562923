import React, { useEffect } from "react";
import Header2 from "./Header2";
import Personalloan from "../Media/Personal Loan.svg";
import "./PersonalLoan.css";
import Footer from "./Footer";
import './Privacypolicy.css';
import { Link } from "react-router-dom";
function PersonalLoan() {
  useEffect(() => {
    return window.scrollTo(0, 0);
  });
 
  return (
    <div>
      <Header2 />
      <div className="personalloan">
        <div className="personalloan__image"
          data-aos="slide-left"  >
          <div className="personalloan__image__heading">
            <h1 className="personalloan__image__heading__title">
              Get Instant approval in maximum loan eligibility from a wide choice of lenders
            </h1>
          </div>
          <img
            className="personalloan__image__logo"
            src={Personalloan}
            alt=""
          />
        </div>

        <div className="personalloan__heading">
          <div className="personalloan__heading__pl">
            <h2 className="personalloan__heading__title">Personal Loan</h2>
            <p className="personalloan__heading__texts">
              Personal loans are an unsecured form of credit that is popular to
              meet immediate requirements. It is multi-purpose in nature and
              therefore can be used for various purposes including wedding, home
              renovation, travel purposes and more. Moreover, there is no
              restriction for the amount borrowed and can be used for any
              purpose they want. In fact, a personal loan can help you build
              your credit faster when compared with other forms of credit. This
              is because of the risk it carries due to being unsecured.
              </p> 
              <p className="personalloan__heading__texts paragraph"> All in all, a personal loan can offset any temporary
              financial crisis.  
              </p>
          </div>
          <div className="personalloan__heading__pleligibility">
            <h2 className="personalloan__heading__title">
              Personal Loan Eligibility Criteria
            </h2>
            <p className="personalloan__heading__texts">
              Personal loans eligibility criteria can be fairly stringent,
              considering banks need to mitigate their risk. Most people with a
              regular source of income such as salaried individuals or
              self-employed individuals can avail a personal loan. Banks
              consider salaried individuals, self-employed professionals and
              self-employed business people.
              </p>
              <p className="personalloan__heading__texts paragraph">
              All in all, a personal loan can offset any temporary
              financial crisis.
            </p>
          </div>

          <div className="personalloan__heading__plamount">
            <h2 className="personalloan__heading__title">
              Personal Loan Amount
            </h2>
            <p className="personalloan__heading__texts">
              An individual can borrow as much as they can repay. This in
              banking terms would mean a personal loan that has an EMI that does
              not exceed 40% of your monthly take home income, where the EMIs
              for existing loans are also deducted.
            </p>
          </div>
          <div className="personalloan__heading__plir">
            <h2 className="personalloan__heading__title">
              Personal Loan Interest Rates
            </h2>
            <p className="personalloan__heading__texts">
              Personal loan rates vary from bank to bank, and are anywhere
              between 14%-26% depending upon your profile and the
              policies/scheme you decide to opt for. In case you need to find
              out more about personal loan rates from different banks, please
              take a look at our compare personal loans page.
              </p><p className="personalloan__heading__texts paragraph">Our loans works with a wide range of personal loan
              providers to get you the best deals and interest rates for your
              financial profile.
            </p>
          </div>
          <div className="personalloan__heading__plfast">
            <h2 className="personalloan__heading__title">
              How Fast Can I Get a Personal Loan? (Instant Loans)
            </h2>
            <p className="personalloan__heading__texts">
              Personal loans don’t take too long to process. Banks usually
              disburse your loan within seven working days. However, it is
              recommended that you keep all your documents ready and in order,
              especially the post dated cheques (PDC), to avoid any delays. How
              fast you get your personal loan is dependent on your document
              collection process.
            </p>
          </div>
          <div className="personalloan__heading__plcoapp">
            <h2 className="personalloan__heading__title">
              Personal Loan Co-Applicants
            </h2>
            <p className="personalloan__heading__texts">
              Personal loans can be taken with Co-Applicants to help you
              increase the loan amount you are eligible for. The income of the
              co-applicant also features in your valuation and helps in
              increasing your chances of getting a personal loan.
            </p>
          </div>
         
        </div>
      </div>
      <div className="getintouch">
        <h1 className="getintouch__title" data-aos='fade-up'>Take your step towards your financial goals</h1>
        <Link to="/BasicDetail" className="header__link">
        <button className="getintouch__button" data-aos='fade-up'>Get In Touch</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default PersonalLoan;
