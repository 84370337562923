import React from "react";
import "./Scrollbar.css";
import "../loancalc"
function Scrollbar() {

  return (
    <div className="scrollbar">
      <div className="scrollbar_range">
        <div className="scrollbar_thumb" data-aos="fade-down" ></div>
        <div className="scrollbar_thumb2" data-aos="fade-down" ></div>
        <div className="scrollbar_thumb3" data-aos="fade-down" ></div>
        <div className="scrollbar_thumb4" data-aos="fade-down" ></div>
      </div>
    </div>
  );
}

export default Scrollbar;
