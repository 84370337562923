import React from 'react'
import './Title.css'
function Title(props) {
  return (
    <div className="mb-5" data-aos="fade-up">
    <p className="title">{props.name}</p>
    </div>
  )
}

export default Title
