import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import React from 'react';
import Home from "./Home"
import ProfessionalLoan from "./Components/ProfessionalLoan"
import PersonalLoan from "./Components/PersonalLoan"
import LoanAgainstProperty from "./Components/LoanAgainstProperty"
import Businessloan from "./Components/Businessloan"
import ContactUs from "./Components/ContactUs"
import Partner from "./Components/Partner"
import Privacypolicy from "./Components/Privacypolicy"
import BasicDetail from './Components/BasicDetail';
function App() {
  return (
    <Router>
      <div className="app">
          <Switch>
            <Route path="/BasicDetail">
              <BasicDetail />
            </Route>
            <Route path="/Privacypolicy">
              <Privacypolicy />
            </Route>
            <Route path="/ProfessionalLoan">
              <ProfessionalLoan />
            </Route>
            <Route path="/PersonalLoan">
              <PersonalLoan />
            </Route>
            <Route path="/LoanAgainstProperty">
              <LoanAgainstProperty />
            </Route>
            <Route path="/Businessloan">
              <Businessloan />
            </Route>
            <Route path="/Partner">
              <Partner />
            </Route>
            <Route path="/contactus">
              <ContactUs />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
      </div>
    </Router>
  );
}

export default App;
